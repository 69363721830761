import "@hotwired/turbo-rails";

import Vue from "vue";
const App = () => import("dependencies/application.js");
const AdminApp = () => import("dependencies/admin.js");
const HubApp = () => import("hub/App.vue");
const CustomerSupportApp = () => import("dependencies/customer_support.js");
const HomeApp = () => import("dependencies/home.js");
const NewHub = () => import("dependencies/new_hub.js");

import DisplaySettingsApp from "DisplaySettings.vue";

import "stylesheets/application.js";

import "./controllers";

import axios from "axios";

import initializeVue from "lib/initializeVue.js";
initializeVue({ elId: "hub-app", render: (h) => h(HubApp) });
initializeVue({
  elId: "display-settings-app",
  render: (h) => h(DisplaySettingsApp),
});

document.addEventListener("turbo:load", () => {
  const appEl = document.getElementById("app");
  if (appEl) {
    App();
  }

  const adminEl = document.getElementById("admin-app");
  if (adminEl) {
    AdminApp();
  }

  const metaValue = document.querySelector("meta[name=csrf-token]");
  if (metaValue) {
    axios.defaults.headers.common["X-CSRF-Token"] = metaValue["content"];
  }

  const customerSupportEl = document.getElementById("customer-support");
  if (customerSupportEl) {
    CustomerSupportApp();
  }
  const homeEl = document.getElementById("home-app");
  if (homeEl) {
    HomeApp();
  }

  const newHubEl = document.getElementById("new-hub");
  if (newHubEl) {
    NewHub();
  }
});

function uninitialize() {
  if (app) {
    app.$destroy();
    app = null;
  }
}
document.addEventListener("turbo:turbo:before-visit", uninitialize);
