import { Controller } from "@hotwired/stimulus";
import { useClickOutside, useTransition } from "stimulus-use";

import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
} from "@floating-ui/dom";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["button", "menu"];
  async connect() {
    useClickOutside(this);
    useTransition(this, { element: this.menuTarget });

    const placement = this.element.getAttribute("data-placement") || "bottom";

    const updatePosition = async () => {
      const position = await computePosition(
        this.buttonTarget,
        this.menuTarget,
        {
          placement: placement,
          middleware: [flip(), shift({ padding: 5 })],
        }
      );

      Object.assign(this.menuTarget.style, {
        left: `${position.x}px`,
        top: `${position.y}px`,
      });
    };

    const cleanup = autoUpdate(
      this.buttonTarget,
      this.menuTarget,
      updatePosition
    );
  }

  toggle() {
    this.toggleTransition();
  }

  open() {
    this.enter();
  }

  close() {
    this.leave();
  }
}
