import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export default function (message, type = "success") {
  Toastify({
    text: message,
    duration: 2000,
    gravity: "top",
    position: "center",
    backgroundColor: type === "alert" ? "#dc2626" : "#10b981",
  }).showToast();
}
