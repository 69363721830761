import uninitializeVue from "lib/uninitializeVue.js";
import Vue from "vue";
export default function initializeVue(options) {
  let app;
  function initialize() {
    let el;
    if (options.elId) {
      el = document.getElementById(options.elId);
      options.el = el;
    } else {
      el = options.el;
    }
    if (!el) {
      return;
    }
    app = new Vue(options);
  }
  initialize();
  document.addEventListener("turbo:load", initialize);

  uninitializeVue(app, initialize);
}
